<template>
  <div>
    <v-btn
      v-if="view"
      color="error"
      @click="dialog = true"
    >
      Cancelar Postulación
    </v-btn>
    <v-dialog v-model="dialog" max-width="400px" :persistent="load">
      <v-card>
        <v-card-title class="font-weight-light" style="word-break: normal">¿Realmente desea cancelar su postulación de esta búsqueda activa?</v-card-title>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn color="success" class="mr-2" :loading="load" @click="confirmar">Sí</v-btn>
          <v-btn color="error" :disabled="load" @click="dialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { db } from '../../firebase'

export default {
  data() {
    return {
      view: true,
      dialog: false,
      load: false,
    }
  },
  props: {
    conv: String,
  },
  created() {
    if (this.$route.name !== 'Postulaciones') {
      this.view = false
      return
    }
    if (this.user.postulaciones.find((conv => conv.convocatoria === this.conv && conv.estado !== 'Cancelada por el postulante')).etapa !== '') this.view = false
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['postulaciones'])
  },
  methods: {
    ...mapActions(['update_user']),
    async confirmar() {
      const index = this.user.postulaciones.indexOf(this.user.postulaciones.find((conv => conv.convocatoria === this.conv && conv.estado !== 'Cancelada por el postulante')))
      let postulaciones = [...this.user.postulaciones]
      postulaciones[index].estado = 'Cancelada por el postulante'

      this.load = true
      await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(this.user.id).delete()
        .then(async () => {
          await db.collection('usuarios').doc(this.user.id).update({ postulaciones: postulaciones })
            .then(() => {
              this.user.postulaciones[index].estado = 'Cancelada por el postulante'
              this.update_user(this.user)
              this.$router.push('/')
            })
            .catch((error) => {
              console.log(error.message)
            })
        })
        .catch((error) => {
          console.log(error.message)
        })
      this.load = false
    }
  }
}
</script>