<template>
  <v-card class="px-4 mb-8" :style="readonly">
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="3">
        <v-card elevation="0" :style="readonly">
          <v-card-title class="font-weight-light py-1">Estado de la búsqueda</v-card-title>
          <v-divider color="grey"></v-divider>
          <v-card-text>
            <v-sheet :style="readonly" rounded elevation="2" class="d-flex justify-center body-1 pa-2">
              <v-icon v-if="estado === 'Activa'" left small class="mb-1">fas fa-star</v-icon>
              <v-icon v-else-if="estado === 'Inactiva'" left small>fas fa-minus-circle</v-icon>
              <v-icon v-else left small class="mb-1">fas fa-flag-checkered</v-icon>
              {{ estado }}
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <v-divider vertical class="mx-6"></v-divider>
      <v-col cols="12" md="4">
        <v-card elevation="0" :style="readonly">
          <v-card-title class="font-weight-light py-1">
            Etapas de la búsqueda
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="view"
                  color="success"
                  title="Añadir"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>fas fa-plus</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-form class="pt-6" ref="form" @submit.prevent="add">
                    <v-select
                      outlined
                      v-model="etapa_nueva"
                      :rules="[rules.required]"
                      :items="etapas_all"
                      label="Nueva etapa"
                      validate-on-blur
                    ></v-select>
                    <div class="d-flex justify-end mb-n1 mt-n1">
                      <v-btn
                        color="error"
                        @click="dialog = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        type="submit"
                        color="success"
                        class="ml-3"
                      >
                        Añadir
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-divider color="grey"></v-divider>
          <v-card-text class="py-1">
            <draggable class="list-group" :list="etapas" group="etapas">
              <v-card
                :disabled="view"
                class="list-group-item py-1 px-2 mt-2"
                :style="readonly_card"
                v-for="(item, index) in etapas"
                :key="index"
              >
                <div class="d-flex">
                  {{ index + 1 }}. {{ item.text }}<v-spacer></v-spacer><v-icon v-if="!view" small>fas fa-grip-lines</v-icon>
                </div>
              </v-card>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card elevation="0" :style="readonly">
          <v-card-title class="font-weight-light py-1">
            Etapas (del puesto)
          </v-card-title>
          <v-divider color="grey"></v-divider>
          <v-card-text class="py-1">
            <draggable class="list-group" :list="etapas_puesto" group="etapas">
              <v-card
                :disabled="view"
                class="list-group-item py-1 px-2 mt-2"
                :style="readonly_card"
                v-for="(item, index) in etapas_puesto"
                :key="index"
              >
                <v-icon v-if="!view" left small>fas fa-arrows-alt</v-icon>{{ item.text }}
              </v-card>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center" v-if="!view">
      <v-col cols="3"></v-col>
      <v-divider vertical class="mx-6"></v-divider>
      <v-col cols="12" md="8" class="d-flex justify-center body-2">(Arrastre las etapas que quiera agregar o quitar a la búsqueda)</v-col>
    </v-row>
  </v-card>
</template>

<script>
import { db } from '../firebase'
import draggable from 'vuedraggable'

export default {
  data () {
    return {
      dialog: false,
      etapa_nueva: '',
      etapas_all: [],
      etapas_puesto: [],
      rules: { required: value => !!value || 'Campo requerido' },
    }
  },
  async created() {
    await db.collection('etapas').where('inhabilitado', '==', false).get()
      .then((query) => {
        query.forEach((doc) => {
          this.etapas_all.push({
            text: doc.data().nombre,
            value: doc.id,
          })
        })
      })
      .catch((error) => {
        console.log(error)
      })
    
    await db.collection('puestos').doc(this.convocatoria.puesto.value).get()
      .then(doc => {
        if (doc.exists) {
          const etapas = doc.data().etapas
          etapas.forEach(etapa => {
            let result = this.etapas_all.find(etapa_all => etapa_all.value === etapa)
            if (result) {
              if (!this.etapas.find(etapa => etapa.value === result.value)) {
                this.etapas_puesto.push({
                  value: result.value,
                  text: result.text,
                })
              }
            }
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  props: {
    view: Boolean,
    convocatoria: Object,
    estado: String,
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  components: {
    draggable
  },
  computed: {
    etapas: {
      get () {
        return this.convocatoria.etapas
      },
      set (value) {
        this.$emit('update:convocatoria.etapas', value)
      }
    },
    readonly(){
      return [ this.view ? { 'background-color' : '#f0f0f0' } : {} ]
    },
    readonly_card(){
      return [ this.view ? { 'background-color' : '#f0f0f0' } : { 'cursor' : 'pointer' } ]
    },
  },
  methods: {
    add() {
      if (this.$refs.form.validate()) {
        const nueva = this.etapas_all.find(etapa => etapa.value === this.etapa_nueva)
        if (!this.etapas.find(etapa => etapa.value === nueva.value)) {
          this.etapas.unshift(nueva)
        }
        this.etapas_puesto = this.etapas_puesto.filter(etapa => etapa.value !== nueva.value)
        this.dialog = false
      }
    },
    close() {
      this.etapa_nueva = ''
      this.$refs.form.resetValidation()
    },
  }
}
</script>