<template>
  <v-dialog
    v-model="dialog"
    width="500"
    :persistent="load"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Cargar pasantes<v-icon right>fas fa-upload</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pt-6 px-4">
        <v-row
          no-gutters
          class="d-flex justify-center mx-4"
          style="background-color: #f0f0f0; border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer"
          @dragover.prevent="icon_large = true"
          @dragleave.prevent="icon_large = false"
          @drop.prevent="drop"
          @click="$refs.input.click()"
        >
          <input type="file" ref="input" class="d-none" accept=".xlsx,.xls,.ods" @change="buscar($event)">
          <v-col class="d-flex justify-center mt-6" cols="12">
            <v-icon :large="icon_large" :color="icon_color">{{ icon }}</v-icon>
          </v-col>
          <v-col class="d-flex justify-center mt-2 mb-4" cols="12">
            <h1 class="body-1 font-weight-bold mx-4 text-center">{{ text }}</h1>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row no-gutters>
          <v-col class="d-flex justify-end align-center mb-md-0 mb-2">
            <h1 class="body-1 error--text mr-4 mt-n2">{{ message }}</h1>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              color="error"
              :disabled="load"
              @click="dialog = false"
            >Cancelar</v-btn>
            <div v-if="file" class="mx-4">
              <v-btn
                v-if="cantidad === 0"
                color="success"
                :loading="load"
                @click="subir"
              >Cargar<v-icon right>fas fa-file-upload</v-icon></v-btn>
              <Confirmar
                v-else
                :loading="load"
                @action="subir"
                nombre="Cargar"
                icon="fas fa-file-upload"
                texto="Ésta búsqueda ya tiene una lista de pasantes precargados, ¿desea reemplazar los datos?"
              />
            </div>
            <v-btn
              v-else
              class="mx-4"
              color="primary"
              @click="$refs.input.click()"
            >Buscar<v-icon right>fas fa-folder-open</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import XLSX from 'xlsx'
import Confirmar from '../utils/Confirmar'

export default {
  data() {
    return {
      file: null,
      dialog: false,
      load: false,
      icon_large: false,
      icon_color: '',
      icon: 'fas fa-file-upload',
      text: 'Arrastre el archivo o haga click aquí',
      message: '',
      list: [],
    }
  },
  props: {
    convocatoria: String,
    lista: Array,
    alerta: Object,
    cantidad: Number,
  },
  components: {
    Confirmar
  },
  watch: {
    file(val) {
      if (this.file) {
        this.text = val.name
      }
    },
    dialog (val) {
      val || this.clear()
    },
  },
  computed: {
    alert: {
      get () {
        return this.alerta
      },
      set (value) {
        this.$emit('update:alerta', value)
      }
    },
    pasantes: {
      get () {
        return this.lista
      },
      set (value) {
        this.$emit('update:lista', value)
      }
    },
  },
  methods: {
    drop(event) {
      if (event.dataTransfer.files.length > 1) {
        this.clear()
        this.message = 'Solo puede subir 1 planilla a la vez'
        return
      }
      this.validar(event.dataTransfer.files[0])
    },
    buscar(event) {
      const archivo = event.target.files[0]
      if (archivo !== undefined) this.validar(archivo)
    },
    validar(archivo) {
      const tipo = archivo.type
      this.icon_large = true
      this.message = ''
      if (tipo === 'application/pdf' || tipo === 'application/vnd.ms-excel' ||  tipo === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || tipo === 'application/vnd.oasis.opendocument.spreadsheet') {
        this.file = archivo
        this.icon = 'fas fa-file-excel'
        this.icon_color = 'green darken-4'
        this.cargar()
      } else {
        this.invalido()
      }
    },
    async subir() {
      this.load = true
      await db.collection('convocatorias').doc(this.convocatoria).update({ 'pasantia.pasantes': this.list })
        .then(async () => {
          this.pasantes = this.list
          this.alert.value = true
          this.alert.text = `Se cargaron correctamente ${this.list.length} postulantes a la lista de pasantes admitidos`
          this.alert.type = 'success'
        })
        .catch((error) => {
          this.alert.value = true
          this.alert.text = error.message
          this.alert.type = 'error'
        })
      this.load = false
      this.dialog = false
    },
    cargar() {
      this.list = []
      const reader = new FileReader()
      reader.onload = (e) => {
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        data.shift()
        data.forEach((row) => {
          if (row[0] !== undefined && row[1] !== undefined) {
            this.list.push({
              nombre: row[0],
              dni: row[1],
            })
          }
        })
        if (this.list.length === 0) {
          this.clear()
          this.message = 'No se encontraron filas en el documento'
        }
      }
      reader.readAsBinaryString(this.file)
    },
    invalido() {
      this.clear()
      this.message = 'Tipo de archivo no válido'
    },
    clear() {
      this.$refs.input.value = ''
      this.file = null
      this.icon_large = false
      this.icon_color = ''
      this.icon = 'fas fa-file-upload'
      this.text = 'Arrastre el archivo o haga click aquí'
      this.message = ''
    },
  }
}
</script>