<template>
  <div>
    <v-row no-gutters>
      <v-col v-if="!(modal && alert.value)">
        <v-expansion-panels v-model="panel" class="px-md-2 pt-3">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h5 font-weight-light py-0">
              <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="py-1">
              <v-form ref="form" @submit.prevent="get_postulantes">
                <v-row>
                    <v-col cols="6" md="2">
                      <v-select
                        v-model="filtro.provincia"
                        :items="nombre_provincias"
                        clearable
                        label="Provincia"
                        @change="get_departamentos"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-select
                        v-model.trim="filtro.departamento"
                        :items="departamentos"
                        clearable
                        label="Departamento"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model.trim="filtro.localidad"
                        label="Localidad"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-select
                        v-model.trim="filtro.visto"
                        :items="visto"
                        label="Estado"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-select
                        v-model.trim="filtro.rechazado"
                        :items="rechazado"
                        label="Mostrar"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="2" class="d-flex justify-end">
                      <v-switch
                        v-model="filtro.carnet"
                        label="Carnet de conducir"
                        class="mt-2 ml-2"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="5">
                    <v-row no-gutters>
                      <v-col cols="12" md="3" class="d-flex align-center body-1 font-weight-light">
                        <div>Fecha de postulación</div>
                      </v-col>
                      <v-col>
                        <FechaFiltro tipo="Desde" />
                      </v-col>
                      <v-col class="ml-4">
                        <FechaFiltro tipo="Hasta" />
                      </v-col>
                    </v-row>
                  </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model.trim="filtro.apellido"
                        label="Apellido/s"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model.trim="filtro.nombre"
                        label="Nombre/s"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model.trim="filtro.etapa"
                        :items="etapas"
                        clearable
                        label="Etapa alcanzada"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model.trim="filtro.telefono"
                        v-mask="'###############'"
                        label="Teléfono"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="10" class="d-flex align-center justify-end">
                      <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon small right>fas fa-search</v-icon></v-btn>
                      <div class="ml-md-6 ml-4"><LimpiarFiltro /></div>
                    </v-col>
                  </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="postulantes"
          :loading="load"
          :show-select="estado !== 'Finalizada'"
          class="elevation-1 my-3 mx-md-2 mx-0"
          :item-class="itemRowBackground"
          :search="search"
          sort-by="fecha"
          sort-desc
          multi-sort
        >
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  hide-details
                  single-line
                  outlined
                  dense
                >
                  <template v-slot:append>
                    <v-icon
                      class="mt-1"
                      title="Buscar en los datos filtrados"
                      small
                    >
                      fas fa-search
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <SendWpp :small="true" :numero="item.telefono" :estado="item.estado" :etapa="item.etapa" />
            <VerCV :item="item" :conv="convocatoria" />
            <NoVisto :item="item" :conv="convocatoria" />
            <Desrechazar v-if="!item.activo" :item="item" :conv="convocatoria" />
            <v-btn
              v-if="item.video"
              :href="item.link"
              target="_blank"
              small
              icon
              @click="marcar_video_visto(item)"
            >
              <v-icon
                :color="item.video_visto ? '' : 'purple'"
                :title="item.video_visto ? 'Video visto' : 'Ver video'"
                class="ml-1"
                small
              >fas fa-video</v-icon>
            </v-btn>
            <v-icon
              v-text="item.comentario ? 'fas fa-comment-dots' : 'fas fa-comment-medical'"
              :color="item.comentario ? 'orange' : ''"
              :title="item.comentario ? 'Ver comentario' : 'Agregar comentario'"
              class="ml-1"
              small
              @click="open_comentario(item)"
            ></v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto my-3"
              max-width="400"
              border="left"
              dense
              text
              type="warning"
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="alert.value"
      timeout="5000"
      class="body-1 mb-4"
      :color="alert.type"
    >
      {{ alert.message }}
    </v-snackbar>

    <Comentario
      v-model="dialog_comentario"
      :comentario="comentario"
      @actualizar="actualizar_comentario"
      @eliminar="eliminar_comentario"
    />

    <v-row class="my-1 mx-1 col-xl-11 d-flex justify-end">
      <v-btn
        class="mb-2 mb-md-0 mr-n2 mr-md-0"
        :color="modal ? estado === 'Finalizada' ? 'success' : 'error' : 'accent'"
        :disabled="load"
        @click="volver"
      >
        <div v-if="modal">
          <div v-if="estado === 'Finalizada'">Aceptar</div>
          <div v-else>Cancelar</div>
        </div>
        <div v-else>Volver</div>
      </v-btn>
      <download-excel
        v-if="postulantes.length !== 0 && !modal"
        :fetch="get_data"
        :fields="json_fields"
        :name="`Postulantes - Búsqueda ${convocatoria}.xls`"
      >
        <v-btn
          color="green darken-4"
          class="ml-4 mr-md-0 mr-n2"
          dark
          :loading="downloading"
        >
          Descargar<v-icon right>fas fa-file-download</v-icon>
        </v-btn>
      </download-excel>
      <Pasantes v-if="pasantia && !modal" :convocatoria="convocatoria" />
      <template v-if="selected.length !== 0 && estado !== 'Finalizada'">
        <template v-if="!modal">
          <RechazarPostulantes :conv="convocatoria" :postulantes_list.sync="postulantes" :selected_list.sync="selected" :alerta.sync="alert" />
          <FinalizarConvocatoria
            :conv="convocatoria"
            :postulantes_list.sync="postulantes"
            :selected_list.sync="selected"
            :alerta.sync="alert"
          />
          <SiguienteEtapa :conv="convocatoria" :postulantes_list.sync="postulantes" :selected_list.sync="selected" :alerta.sync="alert" />
        </template>
        <FinalizarConvocatoria
          v-else
          :conv="convocatoria"
          :postulantes_list.sync="postulantes"
          :selected_list.sync="selected"
          :alerta.sync="alert"
        />
      </template>
    </v-row>
    <v-row class="pl-2">
      <v-col cols="12" md="3">
        <v-badge color="yellow lighten-2" class="mr-6"></v-badge> Inscripto en más de una búsqueda
      </v-col>
      <v-col cols="6" md="2">
        <v-badge color="blue lighten-4" class="mr-6"></v-badge> Visto
      </v-col>
      <v-col cols="12" md="3">
        <v-badge color="blue lighten-2" class="mr-6"></v-badge> Visto e inscripto en más de una búsqueda
      </v-col>
      <v-col cols="6" md="2">
        <v-badge color="red lighten-3" class="mr-6"></v-badge> No avanza
      </v-col>
      <v-col cols="6" md="2">
        <v-badge color="light-green lighten-2" class="mr-6"></v-badge> Contratado
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from '../firebase'
import { mapState } from 'vuex'
import Pasantes from '../components/utils/Pasantes'
import SiguienteEtapa from '../components/utils/SiguienteEtapa'
import LimpiarFiltro from '../components/utils/LimpiarFiltro'
import SendWpp from '../components/utils/SendWpp'
import FinalizarConvocatoria from '../components/utils/FinalizarConvocatoria'
import RechazarPostulantes from '../components/utils/RechazarPostulantes'
import NoVisto from '../components/utils/NoVisto'
import VerCV from '../components/utils/VerCV'
import FechaFiltro from '../components/utils/FechaFiltro'
import Desrechazar from '../components/utils/Desrechazar'
import Comentario from '../components/utils/Comentario'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      load_cv: false,
      downloading: false,
      dialog_comentario: false,
      comentario: {},
      cant_pos: 0,
      etapas: [],
      nombre_provincias: [],
      departamentos: [],
      postulantes: [],
      selected: [],
      headers: [
        { text: 'Apellido', value: 'apellido', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Teléfono', value: 'telefono', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Etapa alcanzada', value: 'etapa', sortable: true },
        { text: 'Fecha postulación', value: 'fecha', sortable: true },
        { text: 'Estado', value: 'visto_text', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
      json_fields: {
        'Apellido/s': 'apellido',
        'Nombre/s': 'nombre',
        'CUIL': 'cuil',
        'Residencia actual': 'lugar_residencia',
        'Fecha nacimiento': 'fecha_nacimiento',
        'Lugar nacimiento': 'lugar_nacimiento',
        'Teléfono celular': 'telefono_celular',
        'Teléfono alternativo': 'telefono_alternativo',
        'Correo electrónico': 'email',
        'Disponibilidad horaria': 'disp_horaria',
        'Pretensión salarial': 'pretension_salarial',
        'Carnet de conducir': 'carnet_conducir',
      },
      alert: {
        value: false,
        type: 'info',
        message: '',
      },
      visto: [
        'No visto',
        'Visto',
        'Todos',
      ],
      rechazado: [
        'Continua',
        'No avanza',
        'Todos',
      ],
    }
  },
  async created() {
    this.provincias.forEach(provincia => {
      this.nombre_provincias.push(provincia.nombre)
    })
    if (this.modal) this.panel = 1
    this.load = true
    await db.collection('convocatorias').doc(this.convocatoria).get()
      .then(async (doc) => {
        if (doc.exists) {
          const etapas = doc.data().etapas
          this.etapas.push('Inicial')
          etapas.forEach((etapa) => {
            this.etapas.push(etapa.text)
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
    this.load = false
  },
  props: {
    convocatoria: String,
    pasantia: Boolean,
    modal: {
      type: Boolean,
      default: false,
    },
    dialogo: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Pasantes, SiguienteEtapa, LimpiarFiltro, SendWpp, FinalizarConvocatoria, RechazarPostulantes, NoVisto, VerCV, FechaFiltro, Desrechazar, Comentario
  },
  computed: {
    ...mapState(['filtro', 'provincias', 'estado']),
    dialog: {
      get () {
        return this.dialogo
      },
      set (value) {
        this.$emit('update:dialogo', value)
      }
    },
  },
  methods: {
    eliminar_comentario (comentario) {
      const post = this.postulantes.find(post => post.id == comentario.postulante_id)
      if (post) {
        post.comentario = undefined
      }
    },
    actualizar_comentario (comentario) {
      const post = this.postulantes.find(post => post.id == comentario.postulante_id)
      if (post) {
        post.comentario = comentario
      }
    },
    open_comentario (item) {
      const comentario = item.comentario
      if (comentario) {
        this.comentario = JSON.parse(JSON.stringify(comentario))
      } else {
        this.comentario = {}
      }
      this.comentario.convocatoria = this.convocatoria
      this.comentario.postulante_id = item.id
      this.comentario.etapa = item.etapa
      this.dialog_comentario = true
    },
    async marcar_video_visto (postulante) {
      this.$store.state.loading_screen = true
      if (!postulante.video_visto) {
        await db.collection('convocatorias').doc(this.convocatoria).collection('postulantes').doc(postulante.id).update({ video_visto: true })
        .then(() => {
          postulante.video_visto = true
        })
        .catch((error) => {
          console.log(error)
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      }
      this.$store.state.loading_screen = false
    },
    filtrar(postulante) {
      let pasa_carnet = true
      let pasa_provincia = true
      let pasa_departamento = true
      let pasa_etapa = true

      if (this.filtro.carnet) pasa_carnet = postulante.cv.carnet_conducir === this.filtro.carnet
      if (this.filtro.provincia !== null) pasa_provincia = postulante.cv.provincia.indexOf(this.filtro.provincia) >= 0
      if (this.filtro.departamento !== null) pasa_departamento = postulante.cv.departamento.indexOf(this.filtro.departamento) >= 0
      if (this.filtro.etapa !== null) pasa_etapa = postulante.etapa.indexOf(this.filtro.etapa) >= 0

      const pasa_localidad = this.removeAccents(postulante.cv.localidad).indexOf(this.removeAccents(this.filtro.localidad)) >= 0
      const pasa_apellido = this.removeAccents(postulante.apellido).indexOf(this.removeAccents(this.filtro.apellido)) >= 0
      const pasa_nombre = this.removeAccents(postulante.nombre).indexOf(this.removeAccents(this.filtro.nombre)) >= 0

      return pasa_carnet && pasa_provincia && pasa_departamento && pasa_etapa && pasa_apellido && pasa_nombre && pasa_localidad
    },
    async get_postulantes() {
      this.load = true
      this.postulantes = []
      this.cant_pos = 0
      let query_size = 1
      let query_fire = db.collection('convocatorias').doc(this.convocatoria).collection('postulantes')

      // aplica filtro de visto / no visto
      let visto = false
      if (this.filtro.visto === 'Visto') visto = true
      if (this.filtro.visto !== 'Todos') query_fire = query_fire.where('visto', '==', visto)

      // aplica filtro de rechazado / no rechazado
      let estado = true
      if (this.filtro.rechazado === 'No avanza') estado = false
      if (this.filtro.rechazado !== 'Todos') query_fire = query_fire.where('estado', '==', estado)

      // filtro de fechas
      if (this.filtro.fecha.desde !== null ) query_fire = query_fire.where('fecha', '>=', this.filtro.fecha.desde)
      if (this.filtro.fecha.hasta !== null ) query_fire = query_fire.where('fecha', '<=', this.filtro.fecha.hasta)

      // filtro de telefono
      if (this.filtro.telefono) query_fire = query_fire.where('telefono', '==', this.filtro.telefono)

      await query_fire.get()
        .then(async (query) => {
          query_size = query.size
          query.forEach(async (doc) => {
            this.cant_pos++
            const email = doc.data().email
            const fecha = doc.data().fecha
            const telefono = doc.data().telefono
            const visto = doc.data().visto
            const video_visto = doc.data().video_visto
            const activo = doc.data().estado
            const id = doc.id

            await db.collection('usuarios').doc(id).get()
              .then(async (doc) => {
                if (doc.exists) {
                  let postulante = doc.data()
                  postulante.id = id
                  postulante.email = email
                  postulante.fecha = this.formatDate(fecha)
                  postulante.telefono = telefono
                  postulante.visto = visto
                  postulante.video_visto = video_visto
                  postulante.activo = activo
                  if (visto) {
                    postulante.visto_text = 'Visto'
                  } else {
                    postulante.visto_text = 'No visto'
                  }

                  const postulaciones = postulante.postulaciones.filter(post => post.estado !== 'Cancelada por el postulante')
                  postulante.masdeuna = postulaciones.length > 1

                  const postulacion = postulante.postulaciones.find(post => post.convocatoria === this.convocatoria && post.estado !== 'Cancelada por el postulante')
                  postulante.estado = postulacion.estado
                  const nombre_etapa = postulacion.etapa

                  // verifica si el postulante subio un video
                  const video = postulante.archivos.find(arch => arch.tipo === 'Video')
                  if (video) {
                    postulante.video = true
                    postulante.link = video.link
                  }

                  if (nombre_etapa === '') {
                    postulante.etapa = 'Inicial'
                  } else {
                    postulante.etapa = nombre_etapa
                  }

                  // obtiene el comentario del postulante
                  try {
                    await db.collection('usuarios').doc(id).collection('comentarios').doc(this.convocatoria).get()
                      .then((doc) => {
                        if (doc.exists) {
                          postulante.comentario = doc.data()
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  } catch (error) {
                    console.log(error)
                  }

                  if (this.filtrar(postulante)) {
                    this.postulantes.push(postulante)
                  }

                }
              })
              .catch((error) => {
                console.log(error)
              })

          })

        })
        .catch((error) => {
          console.log(error)
        })
      await new Promise(resolve => setTimeout(resolve, 15 * query_size))
      this.load = false
    },
    async get_data() {
      this.downloading = true
      let json_data = []
      for (let index = 0; index < this.postulantes.length; index++) {
        const postulante = this.postulantes[index]
        await db.collection('usuarios').doc(postulante.id).get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data().cv
            json_data.push({
              apellido: postulante.apellido,
              nombre: postulante.nombre,
              cuil: data.cuil,
              lugar_residencia: data.lugar_residencia,
              fecha_nacimiento: this.formatDate(data.nacimiento.fecha),
              lugar_nacimiento: data.nacimiento.lugar,
              telefono_celular: postulante.telefono,
              telefono_alternativo: data.telefono.alternativo,
              email: postulante.email,
              disp_horaria: data.disp_horaria,
              pretension_salarial: this.parseMoney(data.pretension_salarial),
              carnet_conducir: this.parseBool(data.carnet_conducir),
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
      }
      this.downloading = false
      return json_data
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseBool (boolean) {
      if (boolean) return 'Sí'
      return 'No'
    },
    parseMoney (money) {
      if (money) return `$${money}`
    },
    itemRowBackground (item) {
      return item.activo ? (
        item.estado === 'Contratado' ? 'background-color: verde' : (
          item.visto ? (
            item.masdeuna ? 'background-color: azul' : 'background-color: celeste'
          ) : (
            item.masdeuna ? 'background-color: amarillo' : ''
          )
        )
      )
      : 'background-color: rojo'
    },
    get_departamentos () {
      this.departamentos = []
      if (this.filtro.provincia) {
        this.departamentos = this.provincias.find(prov => prov.nombre === this.filtro.provincia).departamentos
      }
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    },
    volver () {
      if (this.modal) {
        this.dialog = false
      } else {
        this.$router.push('/admin/convocatorias')
      }
    }
  }
}
</script>