<template>
  <v-btn
    icon
    small
    color="info"
    title="Ver CV"
    :loading="load_cv"
    @click="ver_postulante"
  >
    <v-icon small>fas fa-eye</v-icon>
  </v-btn>
</template>

<script>
import { db } from '../../firebase'

export default {
  data() {
    return {
      load_cv: false,
    }
  },
  props: {
    item: Object,
    conv: String,
  },
  computed: {
    postulante: {
      get () {
        return this.item
      },
      set (value) {
        this.$emit('update:item', value)
      }
    },
  },
  methods: {
    async ver_postulante () {
      this.load_cv = true
      if (this.postulante.visto !== true) {
        await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(this.postulante.id).update({ visto: true })
        .then(() => {
          this.postulante.visto = true
          this.postulante.visto_text = 'Visto'
          this.$store.state.badge.visto ++
        })
        .catch((error) => {
          console.log(error)
        })
      }
      let routeData = this.$router.resolve({path: `/admin/postulante/${this.postulante.id}/${this.postulante.email}/${this.conv}`})
      window.open(routeData.href, '_blank')
      this.load_cv = false
    },
  },
}
</script>