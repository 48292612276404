<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="postulantes"
          :loading="load"
          class="elevation-1 my-3 mx-md-2 mx-0"
          group-by="etapa"
          :item-class="itemRowBackground"
          :items-per-page="-1"
        >
          <template v-slot:[`group.header`]="{ group, headers, isOpen, toggle }">
            <th :colspan="headers.length" class="body-1">
              <v-btn @click="toggle" icon :ref="group" :data-open="isOpen">
                <v-icon>{{ isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</v-icon>
              </v-btn>
              {{ group }}
            </th>
          </template>
          <template v-slot:top>
            <div class="d-flex align-center ml-4">
              <h1 class="text-h5 font-weight-light">Estado de la búsqueda: <strong v-text="estado === 'Activa' ? 'En proceso de selección' : estado"></strong></h1>
              <v-spacer></v-spacer>
              <TooltipIcon clase="ma-2" icon="fas fa-angle-double-up" text="Contraer todos" @action="collapse_all" />
              <TooltipIcon icon="fas fa-angle-double-down" text="Expandir todos" @action="expand_all" />
              <TooltipIcon :load="load" clase="ma-2" icon="fas fa-sync" text="Refrescar" @action="get_postulantes" />
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <SendWpp :numero="item.telefono" :estado="item.estado" :etapa="item.etapa" />
            <v-icon
              small
              color="info"
              title="Ver CV"
              @click="ver_postulante(item)"
            >
              fas fa-eye
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              v-if="postulantes.length === 0"
              class="mx-auto my-3"
              max-width="400"
              border="left"
              dense
              text
              type="info"
            >
              Esta búsqueda no tiene postulantes inscriptos
            </v-alert>
            <v-alert
              v-else
              class="mx-auto my-3"
              max-width="400"
              border="left"
              dense
              text
              type="warning"
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="my-1 mx-1 col-xl-11 d-flex justify-end">
      <v-btn
        class="mb-2 mb-md-0 mr-n2 mr-md-0"
        color="accent"
        :disabled="load"
        @click="$router.push('/admin/convocatorias')"
      >
        Volver
      </v-btn>
    </v-row>
        <v-row class="pl-2">
      <v-col cols="12" md="4">
        <v-badge color="yellow lighten-2" class="mr-6"></v-badge> Inscripto en más de una búsqueda
      </v-col>
      <v-col cols="6" md="2">
        <v-badge color="red lighten-3" class="mr-6"></v-badge> No avanza
      </v-col>
      <v-col cols="6" md="2">
        <v-badge color="light-green lighten-2" class="mr-6"></v-badge> Contratado
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from '../firebase'
import { mapState } from 'vuex'
import TooltipIcon from '../components/utils/TooltipIcon'
import SendWpp from '../components/utils/SendWpp'

export default {
  data () {
    return {
      load: false,
      postulantes: [],
      etapas: [],
      headers: [
        { text: 'Apellido', value: 'apellido', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Teléfono', value: 'telefono', sortable: false, filterable: false },
        { text: 'Email', value: 'email', sortable: false, filterable: false },
        { text: 'Etapa alcanzada ', value: 'etapa', sortable: true, filterable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
    }
  },
  created() {
    this.get_etapas()
    this.get_postulantes()
  },
  props: {
    convocatoria: String,
  },
  components: {
    TooltipIcon, SendWpp
  },
  computed: {
    ...mapState(['estado']),
  },
  methods: {
    async get_postulantes() {
      this.load = true
      this.postulantes = []
      let query_size = 1
      await db.collection('convocatorias').doc(this.convocatoria).collection('postulantes').get()
        .then((query) => {
          query_size = query.size
          query.forEach(async (doc) => {
            let postulante = doc.data()
            postulante.id = doc.id
            postulante.activo = doc.data().estado

            await db.collection('usuarios').doc(postulante.id).get()
              .then(async (doc) => {
                if (doc.exists) {
                  let postulaciones = doc.data().postulaciones
                  const postulacion = postulaciones.find(post => post.convocatoria === this.convocatoria && post.estado !== 'Cancelada por el postulante')
                  const nombre_etapa = postulacion.etapa
                  postulante.estado = postulacion.estado

                  const postu = postulaciones.filter(post => post.estado !== 'Cancelada por el postulante')
                  postulante.masdeuna = postu.length > 1

                  if (nombre_etapa === '') {
                    postulante.etapa = 'Inicial'
                  } else {
                    let numero_etapa = this.etapas.indexOf(this.etapas.find(etp => etp.text === nombre_etapa))
                    numero_etapa++
                    postulante.etapa = nombre_etapa
                  }
                  this.postulantes.push(postulante)
                }
              })
              .catch((error) => {
                console.log(error)
              })

            this.collapse_all()

          })
        })
        .catch((error) => {
          console.log(error)
        })
      await new Promise(resolve => setTimeout(resolve, 15 * query_size))
      this.load = false
    },
    async get_etapas() {
      this.load = true
      await db.collection('convocatorias').doc(this.convocatoria).get()
        .then((doc) => {
          if (doc.exists) {
            this.etapas = doc.data().etapas
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.load = false
    },
    ver_postulante (postulante) {
      let routeData = this.$router.resolve({path: `/admin/postulante/${postulante.id}/${postulante.email}`})
      window.open(routeData.href, '_blank')
    },
    collapse_all() {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },
    expand_all() {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },
    itemRowBackground (item) {
      return item.activo ? (
        item.estado === 'Contratado' ? 'background-color: verde' : (
          item.masdeuna ? 'background-color: amarillo' : ''
        )
      )
      : 'background-color: rojo'
    }
  }
}
</script>