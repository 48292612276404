<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="clase"
        :loading="load"
        icon
        v-bind="attrs"
        v-on="on"
        @click="$emit('action')"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    clase: {
      type: String,
      default: "",
    },
    load: {
      type: Boolean,
      default: false,
    },
    icon: String,
    text: String,
  }
}
</script>