<template>
  <v-dialog
      v-model="dialog"
      width="900"
      :persistent="load"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
        >
          Finalizar búsqueda<v-icon right>fas fa-flag-checkered</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light ml-md-2">Finalizar Búsqueda</v-card-title>
        <v-card-subtitle class="text-h6 font-weight-light py-1 ml-md-2">Seleccione a los postulantes que quedaron para esta búsqueda</v-card-subtitle>
        <v-card-text>
          <Postulantes :convocatoria="convocatoria" :pasantia="false" :activa="true" :modal="true" :dialogo.sync="dialog" />
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import Postulantes from '../Postulantes.vue'

export default {
  data () {
    return {
      dialog: false,
      load: false,
    }
  },
  props: {
    convocatoria: String,
    disabled: Boolean,
  },
  components: {
    Postulantes
  },
}
</script>