<template>
  <v-dialog
    v-model="dialog"
    width="550"
    scrollable
    :persistent="load"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="ml-4 mr-md-0 mr-n2 mb-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Pasantes precargados<v-icon right>fas fa-address-book</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 font-weight-light">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="d-flex align-center">
            Pasantes precargados
          </v-col>
          <v-col cols="12" md="6">
           <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-md-0 mx-4"
            append-icon="fas fa-search"
          ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pb-1">
        <v-data-table
          :headers="headers"
          :items="pasantes"
          :search="search"
          class="mt-n3"
          :loading="load"
        >
          <template v-slot:no-data>
            <v-alert
              class="mx-auto my-3"
              max-width="400"
              border="left"
              dense
              text
              type="warning"
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
        <v-alert
          v-if="alert.value"
          class="mx-auto my-1"
          border="left"
          dismissible
          dense
          text
          :type="alert.type"
        >
          {{ alert.text }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex pb-4">
        <v-row no-gutters class="d-flex justify-end">
          <download-excel
            :fetch="get_data"
            :fields="json_fields"
            :name="`Pasantes precargados - Búsqueda ${convocatoria}.xls`"
          >
            <v-btn
              color="green darken-4"
              class="mb-2 mb-md-0 mr-md-4 mr-0"
              dark
            >
              Descargar<v-icon right>fas fa-file-download</v-icon>
            </v-btn>
          </download-excel>
          <div class="mb-2 mb-md-0">
            <CargarPasantes :convocatoria="convocatoria" :lista.sync="pasantes" :alerta.sync="alert" :cantidad="pasantes.length" />
          </div>
          <v-btn
            color="accent"
            class="ml-4"
            @click="dialog = false"
          >Volver</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import CargarPasantes from '../utils/CargarPasantes'

export default {
  data() {
    return {
      dialog: false,
      load: false,
      pasantes: [],
      search: '',
      headers: [
        { text: 'Nombre', value: 'nombre', sortable: true, filterable: true },
        { text: 'Documento', value: 'dni', sortable: false, filterable: true },
      ],
      json_fields: {
        'Nombre': 'nombre',
        'Documento': 'dni',
      },
      alert: {
        value: false,
        text: '',
        type: 'info',
      }
    }
  },
  props: {
    convocatoria: String
  },
  components: {
    CargarPasantes
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.get_pasantes()
      } else {
        this.clear()
      }
    },
  },
  methods: {
    async get_pasantes() {
      this.load = true
      this.pasantes = []
      await db.collection('convocatorias').doc(this.convocatoria).get()
        .then((doc) => {
          if (doc.exists) {
            const pasantes = doc.data().pasantia.pasantes
            if (pasantes.length === 0) {
              this.alert.value = true
              this.alert.text = 'No se ha cargado ninguna lista de pasantes'
              this.alert.type = 'info'
            } else {
              this.pasantes = pasantes
            }
          }
        })
        .catch((error) => {
          this.alert.value = true
          this.alert.text = error.message
          this.alert.type = 'error'
        })
      this.load = false
    },
    async get_data() {
      if (this.pasantes.length > 0) {
        return this.pasantes
      } else {
        let json_data = []
        json_data.push({ nombre: "", dni: ""})
        return json_data
      }
    },
    clear() {
      this.alert.value = false
    },
  }
}
</script>