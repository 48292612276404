<template>
  <div>
    <Login v-model="show_login" />
    <v-btn
      v-if="view"
      color="success"
      :small="small"
      @click="post"
    >
      Postularme<v-icon right class="d-none d-sm-flex">fas fa-hand-paper</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Login from '../Login'

export default {
  data() {
    return {
      view: true,
      show_login: false,
    }
  },
  props: {
    conv: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.exist_user) {
      if (this.rol === 'Admin') {
        this.view = false
        return
      }
      if (this.postulaciones.find((conv => conv.convocatoria === this.conv && conv.estado !== 'Cancelada por el postulante'))) {
        this.view = false
      }
    }
  },
  components: {
    Login
  },
  watch: {
    exist_user(val) {
      if (!val) this.view = true
    }
  },
  computed: {
    ...mapGetters(['exist_user', 'postulaciones', 'rol'])
  },
  methods: {
    post() {
      if (this.exist_user) {
        this.$router.push(`/cv/${this.conv}`)
      } else {
        this.show_login = true
      }
    }
  }
}
</script>