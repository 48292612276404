<template>
  <v-dialog
      v-model="dialog"
      width="450"
      :persistent="load"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          class="ml-4 mr-md-0 mr-n2 mb-2"
          v-bind="attrs"
          v-on="on"
        >
          No avanza<v-icon right>fas fa-ban</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light">No avanzar Postulantes</v-card-title>

        <v-card-text class="body-1">
          <v-row v-if="!load_conv">
            <v-col cols="12">
              <div v-if="selected.length === 1">
                ¿Desea no avanzar al postulante seleccionado?
              </div>
              <div v-else>
                ¿Desea no avanzar a los {{ selected.length }} postulantes seleccionados?
              </div>
            </v-col>
            <v-col v-if="selected.length == 1" cols="12">
              <v-textarea
                v-model.trim="comentario"
                label="Comentario"
                rows="3"
                hide-details
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-alert
            v-else
            class="text-center mx-md-16 mx-0"
            type="warning"
            border="left"
            dense
            text
          >No se encontró la Búsqueda</v-alert>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-4"
            :disabled="load"
            @click.stop="dialog = false"
          >
            Cancelar
          </v-btn>
          <Confirmar v-if="!load_conv" :loading="load" @action="rechazar" />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import { format_date } from '../../utils'
import Confirmar from './Confirmar.vue'

export default {
  data () {
    return {
      dialog: false,
      load_conv: false,
      load: false,
      convocatoria: {},
      comentario: ''
    }
  },
  async created () {
    this.load_conv = true
    await db.collection('convocatorias').doc(this.conv).get()
      .then((doc) => {
        if (doc.exists) {
          this.convocatoria = doc.data()
        }
      })
      .catch((error) => {
        console.log(error)
      })
    this.load_conv = false
  },
  props: {
    conv: String,
    postulantes_list: Array,
    selected_list: Array,
    alerta: Object,
  },
  components: {
    Confirmar
  },
  computed: {
    ...mapState(['user']),
    postulantes: {
      get () {
        return this.postulantes_list
      },
      set (value) {
        this.$emit('update:postulantes_list', value)
      }
    },
    selected: {
      get () {
        return this.selected_list
      },
      set (value) {
        this.$emit('update:selected_list', value)
      }
    },
    alert: {
      get () {
        return this.alerta
      },
      set (value) {
        this.$emit('update:alerta', value)
      }
    },
  },
  watch: {
    dialog (val) {
      if (val) {
        const comentario = this.selected[0].comentario
        if (comentario) {
          this.comentario = comentario.comentario
        }
      } else {
        this.comentario = ''
      }
    }
  },
  methods: {
    async rechazar() {
      this.load = true

      // actualzia el comentario si hay solo 1 postulante seleccioando
      if (this.selected.length == 1 && this.comentario) {
        try {
          let postulante = this.selected[0]
          let comentario = postulante.comentario
          // si el comentario no existe lo crea
          if (!comentario) {
            comentario = {
              busqueda: this.convocatoria.busqueda,
              periodo: format_date(this.convocatoria.fecha.desde) + ' - ' + format_date(this.convocatoria.fecha.hasta),
              postulante_id: postulante.id,
              etapa: postulante.etapa,
              convocatoria: this.conv,
              usuario: this.user.nombre + ' ' + this.user.apellido
            }
          }
          // cambia el comentario por el nuevo
          comentario.comentario = this.comentario
          // guarda el comentario en la coleccion del usuario
          await db.collection('usuarios').doc(comentario.postulante_id).collection('comentarios').doc(comentario.convocatoria).set(comentario)
            .then(() => {
              // actualiza el comentario del postulante en la lista
              this.postulantes.find(post => post.id == comentario.postulante_id).comentario = comentario
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error al actualizar el comentario del postulante: ' + error.message,
                color: 'error',
              })
            })
        } catch (error) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error general al actualizar el comentario del postulante: ' + error.message,
            color: 'error',
          })
        }
      }

      // "elimina" a los pasantes seleccionados
      for (let index = 0; index < this.selected.length; index++) {
        const id = this.selected[index].id
        await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(id).update({ estado: false })
          .then(async () => {

            // actualiza el estado del usuario
            await db.collection('usuarios').doc(id).get()
              .then(async (doc) => {
                if (doc.exists) {
                  let postulaciones = doc.data().postulaciones
                  postulaciones.find(post => post.convocatoria === this.conv && post.estado !== 'Cancelada por el postulante').estado = 'Rechazado'

                  await db.collection('usuarios').doc(id).update({ postulaciones: postulaciones })
                    .then(() => {
                      // usuario actualizado
                      this.postulantes.find(post => post.id === id).activo = false
                    })
                    .catch((error) => {
                      console.log(error)
                    })

                }
              })
              .catch((error) => {
                console.log(error)
              })

          })
          .catch((error) => {
            console.log(error)
          })
      }

        this.dialog = false
        this.alert.value = true
        this.alert.type = 'success'
        this.alert.message = 'Cambios guardados correctamente'

        //this.postulantes = this.postulantes.filter(post => !this.selected.includes(post))
        this.selected = []

        this.load = false
    },
  },
}
</script>