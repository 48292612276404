<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="750"
      scrollable
    >
      <v-card>
        <v-card-title class="rojobb" style="word-break: normal">
          <h1 class="text-h4 secondary--text">{{ convocatoria.busqueda }}</h1>
          <h3 class="text-h6 secondary--text font-weight-light">
            {{ convocatoria.fecha_desde_format }} - {{ convocatoria.fecha_hasta_format }} - {{ convocatoria.area_nombre }} - {{ convocatoria.provincia }}, {{ convocatoria.departamento }}, {{ convocatoria.localidad }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="mt-3 ">
              <div class="text-h5"><strong class="font-weight-medium">Puesto: </strong>{{ convocatoria.puesto.text }}</div>
              <div class="text-h6 mt-4">Tareas y responsabilidades</div>
              <div v-for="item in funciones_format" :key="item" class="body-1 ml-3 mt-1">
                <v-icon x-small left>fas fa-dot-circle</v-icon>{{ item }}
              </div>
              <div class="text-h6 mt-4">Requisitos</div>
              <div v-for="(item, index) in requisitos_format" :key="index" class="body-1 ml-3 mt-1">
                <strong>{{ index + 1 }}.</strong> {{ item }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <CancelarPostulacion :conv="convocatoria.id" />
          <Postularme :conv="convocatoria.id" />
          <v-btn
            color="accent"
            class="ml-4"
            @click.stop="dialog = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Postularme from '../components/utils/Postularme'
import CancelarPostulacion from '../components/utils/CancelarPostulacion'

export default {
  data () {
    return {
      requisitos_format: [],
      funciones_format: []
    }
  },
  created() {
    if (!this.nueva) {
      this.funciones_format = this.convocatoria.puesto.data.funciones
      this.convocatoria.puesto.data.requisitos.forEach(requisito => {
        let text = requisito.descripcion
        if (requisito.excluyente) {
          text += ' (excluyente)' 
        }
        this.requisitos_format.push(text)
      })
    }
  },
  props: {
    value: Boolean,
    convocatoria: Object,
    nueva: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Postularme, CancelarPostulacion
  },
  watch: {
    funciones () {
      this.funciones_format = []
      this.funciones_format = this.funciones
    },
    requisitos () {
      this.requisitos_format = []
      this.requisitos.forEach(requisito => {
        let text = requisito.descripcion
        if (requisito.excluyente) {
          text += ' (excluyente)' 
        }
        this.requisitos_format.push(text)
      })
    }
  },
  computed: {
    ...mapState('funciones', ['funciones']),
    ...mapState('requisitos', ['requisitos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  }
}
</script>