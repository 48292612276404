<template>
  <v-btn
    icon
    small
    title="Marcar como no visto"
    :loading="load_visto"
    @click="nover_postulante"
  >
    <v-icon small>fas fa-eye-slash</v-icon>
  </v-btn>
</template>

<script>
import { db } from '../../firebase'

export default {
  data() {
    return {
      load_visto: false,
    }
  },
  props: {
    item: Object,
    conv: String,
  },
  computed: {
    postulante: {
      get () {
        return this.item
      },
      set (value) {
        this.$emit('update:item', value)
      }
    },
  },
  methods: {
    async nover_postulante () {
      this.load_visto = true
      if (this.postulante.visto === true) {
        await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(this.postulante.id).update({ visto: false })
        .then(() => {
          this.postulante.visto = false
          this.postulante.visto_text = 'No visto'
          this.$store.state.badge.visto --
        })
        .catch((error) => {
          console.log(error)
        })
      }
      this.load_visto = false
    },
  },
}
</script>