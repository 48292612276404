<template>
  <v-dialog
      v-model="dialog"
      width="600"
      :persistent="load"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="ml-4 mr-md-0 mr-n2 mb-2"
          v-bind="attrs"
          v-on="on"
        >
          Finalizar búsqueda<v-icon right>fas fa-flag-checkered</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light">Finalizar Búsqueda</v-card-title>

        <v-card-text class="body-1">
          ¿Desea finalizar esta búsqieda activa con <strong>{{ selected.length }} postulantes</strong> seleccioandos?
          Los postulantes no seleccioandos (y los que fueron marcados como No avanza) serán informados que no avanzaron en la búsqueda por medio de una notificación.
        </v-card-text>

        <v-card-text class="body-2">
          <strong>Nota:</strong> para modificar la notificación predeterminada puede hacerlo desde el menu Configuración -> Mensajes
        </v-card-text>

        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-4"
            :disabled="load"
            @click.stop="dialog = false"
          >
            Cancelar
          </v-btn>
          <Confirmar nombre="Guardar y finalizar" :loading="load" @action="finalizar" />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapActions } from 'vuex'
import Confirmar from './Confirmar.vue'

export default {
  data () {
    return {
      dialog: false,
      load: false,
    }
  },
  props: {
    conv: String,
    postulantes_list: Array,
    selected_list: Array,
    alerta: Object,
  },
  components: {
    Confirmar
  },
  computed: {
    postulantes: {
      get () {
        return this.postulantes_list
      },
      set (value) {
        this.$emit('update:postulantes_list', value)
      }
    },
    selected: {
      get () {
        return this.selected_list
      },
      set (value) {
        this.$emit('update:selected_list', value)
      }
    },
    alert: {
      get () {
        return this.alerta
      },
      set (value) {
        this.$emit('update:alerta', value)
      }
    },
  },
  methods: {
    ...mapActions(['set_estado']),
    async finalizar() {

      if (this.selected.find(post => !post.activo)) {
        this.dialog = false
        this.alert.value = true
        this.alert.type = 'error'
        this.alert.message = 'No puede finalizara una búsqueda con un postulante que fue rechazado'
        return
      }

      this.load = true

      await db.collection('convocatorias').doc(this.conv).get()
        .then(async (doc) => {
          if (doc.exists) {
            const nombre_conv = doc.data().busqueda

            await db.collection('convocatorias').doc(this.conv).update({ activa: false, estado: 'Finalizada' })
              .then(async () => {

                let notificacion = { titulo: '', texto: '', leida: false }

                // get notificacion de fracaso
                await db.collection('mensajes_predefinidos').doc('no_avanza').get()
                  .then(async (doc) => {
                    if (doc.exists) {
                      notificacion.titulo = doc.data().titulo
                      notificacion.texto = `${doc.data().texto} Convocatoria: ${nombre_conv}`
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })

                // get all postulantes
                await db.collection('convocatorias').doc(this.conv).collection('postulantes').get()
                  .then(async (query) => {
                    for (let index = 0; index < query.size; index++) {
                      const doc = query.docs[index]
                      const id = doc.id

                      // lo logró
                      if (this.selected.find(post => post.id === id)) {

                        // actualiza el estado de los que pasaron a 'Contratado'
                        await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(id).update({ contratado: true })
                          .then(async () => {
                            
                            await db.collection('usuarios').doc(id).get()
                              .then(async (doc) => {
                                if (doc.exists) {
                                  let postulaciones = doc.data().postulaciones
                                  postulaciones.find(post => post.convocatoria === this.conv && post.estado !== 'Cancelada por el postulante').estado = 'Contratado'

                                  await db.collection('usuarios').doc(id).update({ postulaciones: postulaciones })
                                    .then(() => {
                                      // usuario actualizado
                                      this.postulantes.find(post => post.id === id).estado = 'Contratado'
                                    })
                                    .catch((error) => {
                                      console.log(error)
                                    })

                                }
                              })
                              .catch((error) => {
                                console.log(error)
                              })

                          })
                          .catch((error) => {
                            console.log(error)
                          })

                      // no lo logró
                      } else {

                        // actualiza el estado a false y notifica
                        await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(id).update({ estado: false })
                          .then(async () => {

                            await db.collection('usuarios').doc(id).get()
                              .then(async (doc) => {
                                if (doc.exists) {
                                  let notificaciones = doc.data().notificaciones
                                  notificaciones.unshift(notificacion)

                                  let postulaciones = doc.data().postulaciones
                                  postulaciones.find(post => post.convocatoria === this.conv && post.estado !== 'Cancelada por el postulante').estado = 'Rechazado'

                                  await db.collection('usuarios').doc(id).update({ notificaciones: notificaciones, postulaciones: postulaciones })
                                    .then(() => {
                                      // usuario actualizado y notificado
                                      let postulante = this.postulantes.find(post => post.id === id)
                                      if (postulante) {
                                        postulante.estado = 'Rechazado'
                                        postulante.activo = false
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(error)
                                    })

                                }
                              })
                              .catch((error) => {
                                console.log(error)
                              })

                          })
                          .catch((error) => {
                            console.log(error)
                          })

                      }

                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })

                this.set_estado('Finalizada')

                this.dialog = false
                this.alert.value = true
                this.alert.type = 'success'
                this.alert.message = 'Postulantes notificados y cambios guardados correctamente'

                this.selected = []

              })
              .catch((error) => {
                this.alert.value = true
                this.alert.type = 'error'
                this.alert.message = error.message
              })

          }
        })
        .catch((error) => {
          console.log(error)
        })

      this.load = false
    },
  },
}
</script>