<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    :left="$vuetify.breakpoint.xs"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label=tipo
        prepend-icon="fas fa-calendar-day"
        readonly
        :filled="view"
        :clearable="isFiltro"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker
      :readonly="view"
      v-model="date"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      menu: false,
      date: '',
      dateFormatted: '',
    }
  },
  created() {
    if (this.isFiltro) {
      this.cambiar()
    } else {
      this.date = this.defaultDate
    }
  },
  props: {
    tipo: String,
    isFiltro: Boolean,
    isInicio: {
      type: Boolean,
      default: false,
    },
    defaultDate: {
      type: String,
      default: '',
    },
    view: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    filtro_conv: {
      handler: function (val, oldVal) {
        if (this.isFiltro) {
          this.cambiar()
        }
      },
      deep: true
    },
    date () {
      this.dateFormatted = this.formatDate(this.date)
      if (this.tipo === 'Desde') {
        this.set_fecha_desde({ fecha: this.date, filtro: this.isFiltro, inicio: this.isInicio })
        return
      }
      if (this.tipo === 'Hasta') {
        this.set_fecha_hasta({ fecha: this.date, filtro: this.isFiltro, inicio: this.isInicio })
      }
    },
  },
  computed: {
    ...mapState(['filtro_conv'])
  },
  methods: {
    ...mapActions(['set_fecha_desde', 'set_fecha_hasta']),
    cambiar () {
      if (this.isInicio) {
        if (this.tipo === 'Desde') {
          this.date = this.filtro_conv.fecha.inicio.desde
        } else {
          this.date = this.filtro_conv.fecha.inicio.hasta
        }
      } else {
        if (this.tipo === 'Desde') {
          this.date = this.filtro_conv.fecha.fin.desde
        } else {
          this.date = this.filtro_conv.fecha.fin.hasta
        }
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>