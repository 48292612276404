<template>
  <v-dialog
      v-model="dialog"
      width="380"
      :persistent="load"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="ml-4 mr-md-0 mr-n2 mb-2"
          v-bind="attrs"
          v-on="on"
        >
          Avanzar de etapa<v-icon right>fas fa-arrow-alt-circle-right</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light">Avanzar de Etapa</v-card-title>

        <v-card-text class="body-1">
          <v-row v-if="!load_conv">
            <v-col cols="12">
              <div v-if="selected.length === 1">Seleccione la etapa a la que quiere avanzar al postulante seleccionado</div>
              <div v-else>Seleccione la etapa a la que quiere avanzar a los {{ selected.length }} postulantes seleccionados</div>
            </v-col>
            <v-col cols="12">
              <v-form ref="form">
                <v-select
                  v-model="etapa_siguiente"
                  :rules="[rules.required]"
                  :items="convocatoria.etapas"
                  label="Siguiente Etapa"
                  validate-on-blur
                ></v-select>
              </v-form>
            </v-col>
            <v-col v-if="selected.length == 1" cols="12">
              <v-textarea
                v-model.trim="comentario"
                label="Comentario"
                rows="3"
                hide-details
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-alert
            v-else
            class="text-center mx-md-16 mx-0"
            type="warning"
            border="left"
            dense
            text
          >No se encontró la Búsqueda</v-alert>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-4"
            :disabled="load"
            @click.stop="dialog = false"
          >
            Cancela
          </v-btn>
          <Confirmar v-if="!load_conv" :loading="load" @action="avanzar_etapa" />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import { format_date } from '../../utils'
import Confirmar from './Confirmar.vue'

export default {
  data () {
    return {
      dialog: false,
      load_conv: false,
      load: false,
      convocatoria: {},
      etapa_siguiente: '',
      comentario: '',
      rules: { required: value => !!value || 'Campo requerido' },
    }
  },
  async created () {
    this.load_conv = true
    await db.collection('convocatorias').doc(this.conv).get()
      .then((doc) => {
        if (doc.exists) {
          this.convocatoria = doc.data()
        }
      })
      .catch((error) => {
        console.log(error)
      })
    this.load_conv = false
  },
  props: {
    conv: String,
    postulantes_list: Array,
    selected_list: Array,
    alerta: Object,
  },
  components: {
    Confirmar
  },
  computed: {
    ...mapState(['user']),
    postulantes: {
      get () {
        return this.postulantes_list
      },
      set (value) {
        this.$emit('update:postulantes_list', value)
      }
    },
    selected: {
      get () {
        return this.selected_list
      },
      set (value) {
        this.$emit('update:selected_list', value)
      }
    },
    alert: {
      get () {
        return this.alerta
      },
      set (value) {
        this.$emit('update:alerta', value)
      }
    },
  },
  watch: {
    dialog (val) {
      if (val) {
        const comentario = this.selected[0].comentario
        if (comentario) {
          this.comentario = comentario.comentario
        }
      } else {
        this.comentario = ''
        this.close()
      }
    }
  },
  methods: {
    async avanzar_etapa() {
      if (this.$refs.form.validate()) {
        this.load = true

        // actualiza a los pasantes que pasaron
        await db.collection('etapas').doc(this.etapa_siguiente).get()
          .then(async (doc) => {
            if (doc.exists) {

              // actualzia el comentario si hay solo 1 postulante seleccioando
              if (this.selected.length == 1 && this.comentario) {
                try {
                  let postulante = this.selected[0]
                  let comentario = postulante.comentario
                  // si el comentario no existe lo crea
                  if (!comentario) {
                    comentario = {
                      busqueda: this.convocatoria.busqueda,
                      periodo: format_date(this.convocatoria.fecha.desde) + ' - ' + format_date(this.convocatoria.fecha.hasta),
                      postulante_id: postulante.id,
                      convocatoria: this.conv,
                      usuario: this.user.nombre + ' ' + this.user.apellido
                    }
                  }
                  // cambia el comentario por el nuevo
                  comentario.comentario = this.comentario
                  // cambia la etapa del comentario actual por la etapa de la que lo avanza
                  comentario.etapa = this.convocatoria.etapas.find(etapa => etapa.value === this.etapa_siguiente).text
                  // guarda el comentario en la coleccion del usuario
                  await db.collection('usuarios').doc(comentario.postulante_id).collection('comentarios').doc(comentario.convocatoria).set(comentario)
                    .then(() => {
                      // actualiza el comentario del postulante en la lista
                      this.postulantes.find(post => post.id == comentario.postulante_id).comentario = comentario
                    })
                    .catch((error) => {
                      this.$store.dispatch('show_snackbar', {
                        text: 'Ocurrio un error al actualizar el comentario del postulante: ' + error.message,
                        color: 'error',
                      })
                    })
                } catch (error) {
                  this.$store.dispatch('show_snackbar', {
                    text: 'Ocurrio un error general al actualizar el comentario del postulante: ' + error.message,
                    color: 'error',
                  })
                }
              }

              for (let index = 0; index < this.selected.length; index++) {
                const id = this.selected[index].id
                const activo = this.selected[index].activo

                await db.collection('usuarios').doc(id).get()
                  .then(async (doc) => {
                    if (doc.exists) {
                      let postulaciones = doc.data().postulaciones
                      const nombre_etapa = this.convocatoria.etapas.find(etapa => etapa.value === this.etapa_siguiente).text
                      postulaciones.find(post => post.convocatoria === this.conv && post.estado !== 'Cancelada por el postulante').etapa = nombre_etapa

                      // si el usuario esta rechazado actualiza tambien su estado en la coleccion usuarios
                      if (!activo) {
                        postulaciones.find(post => post.convocatoria === this.conv && post.estado !== 'Cancelada por el postulante').estado = 'En proceso de selección'
                      }

                      // actualiza los datos del usuario
                      await db.collection('usuarios').doc(id).update({ postulaciones: postulaciones })
                        .then(async () => {

                          // si el usuario esta rechazado actualiza su estado a no rechazado en los postulantes dentro de la convocatoria
                          if (!activo) {
                            await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(id).update({ estado: true })
                              .then(async () => {
                                this.postulantes.find(post => post.id === id).activo = true
                              })
                              .catch((error) => {
                                console.log(error)
                              })
                          }

                          this.postulantes.find(post => post.id === id).etapa = nombre_etapa
                        })
                        .catch((error) => {
                          console.log(error)
                        })

                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }

            }
          })
          .catch((error) => {
            console.log(error)
          })

        this.dialog = false
        this.alert.value = true
        this.alert.type = 'success'
        this.alert.message = 'Cambios guardados correctamente'
        this.selected = []

        this.load = false
      }
    },
    close () {
      this.etapa_siguiente = ''
      this.$refs.form.resetValidation()
    }
  },
}
</script>