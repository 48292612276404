<template>
  <div class="pb-6">
    <v-row v-if="!err404" class="d-flex justify-center pr-4 mt-n6 py-md-2">
      <v-col cols="8" class="d-flex align-center">
        <v-tabs :vertical="$vuetify.breakpoint.xs" v-model="tab" color="primary">
          <v-tab class="text-h6 font-weight-light">{{ title }}</v-tab>
          <template v-if="!nueva">
            <v-tab class="text-h6 font-weight-light">
              Postulantes
              <v-badge
                v-if="!load_form"
                :content="`${$store.state.badge.visto}/${$store.state.badge.total}`"
                class="mb-1 ml-1 mr-n2"
                inline>
              </v-badge>
            </v-tab>
            <v-tab class="text-h6 font-weight-light">Historial</v-tab>
          </template>
        </v-tabs>
      </v-col>
      <v-col cols="4" class="d-flex justify-end align-top">
        <v-switch
          v-if="!nueva && tab === 0"
          v-model="editar"
          class="mt-2"
          label="Editar"
          hide-details
        ></v-switch>
      </v-col>
    </v-row>

    <div class="text-right mr-2">
      {{ nombre_conv }}
    </div>
    
    <v-tabs-items v-model="tab">
      <v-tab-item class="mt-6">
        <v-row v-if="load_form" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>

        <v-row v-else-if="!err404" class="d-flex justify-center px-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="6" md="2">
                <v-select
                  :readonly="!nueva"
                  :filled="!nueva"
                  v-model="convocatoria.empresa.value"
                  :rules="[rules.required]"
                  :items="empresas_select"
                  label="Empresa"
                  @change="get_areas_x_empresa(convocatoria.empresa.value)"
                ></v-select>
              </v-col>
              <v-col cols="6" md="2">
                <v-select
                  :readonly="!nueva"
                  :filled="!nueva"
                  v-model="convocatoria.area.value"
                  :rules="[rules.required]"
                  :items="areas_x_empresa"
                  label="Área"
                  @change="get_puestos_x_area(convocatoria.area.value)"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  :readonly="!nueva"
                  :filled="!nueva"
                  v-model="convocatoria.puesto.value"
                  :rules="[rules.required]"
                  :items="puestos_x_area"
                  label="Puesto"
                  @change="get_puesto(convocatoria.puesto.value)"
                ></v-select>
              </v-col>
              <v-col cols="8" md="3">
                <v-text-field
                  :readonly="!editar"
                  :filled="!editar"
                  v-model.trim="convocatoria.busqueda"
                  :rules="[rules.required]"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-switch
                  :readonly="!editar"
                  :inset="!editar"
                  v-model="convocatoria.activa"
                  label="Activa"
                ></v-switch>
              </v-col>
            </v-row>

            <v-card class="mb-4">
              <v-card-title class="font-weight-light">Información del puesto</v-card-title>
              <v-card-text>
                <v-row class="mt-n6">
                  <v-col cols="12" md="4">
                    <v-textarea
                      :readonly="!editar"
                      :filled="!editar"
                      v-model.trim="convocatoria.puesto.data.mision"
                      label="Misión"
                      no-resize
                      rows="5"
                      class="mb-n6"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <TablaFunciones :view="!editar" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <TablaRequisitos :view="!editar" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col cols="12" md="4" class="d-flex">
                <div class="mr-2"><Fecha tipo="Desde" :view="!editar" :isFiltro="false" :defaultDate="convocatoria.fecha.desde" /></div>
                <div class="ml-2"><Fecha tipo="Hasta" :view="!editar" :isFiltro="false" :defaultDate="convocatoria.fecha.hasta" /></div>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="6" md="4">
                    <v-select
                      :readonly="!editar"
                      :filled="!editar"
                      v-model="convocatoria.provincia"
                      :rules="[rules.required]"
                      :items="nombre_provincias"
                      @change="get_departamentos"
                      label="Provincia"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-select
                      :readonly="!editar"
                      :filled="!editar"
                      v-model.trim="convocatoria.departamento"
                      :rules="[rules.required]"
                      :items="departamentos"
                      label="Departamento"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      :readonly="!editar"
                      :filled="!editar"
                      v-model.trim="convocatoria.localidad"
                      :rules="[rules.required]"
                      label="Localidad"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.xs" cols="6">
                    <v-text-field
                      :readonly="!editar"
                      :filled="!editar"
                      v-model.trim="convocatoria.vacantes"
                      :rules="[rules.required]"
                      label="Vacantes"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.xs" cols="2">
                <v-text-field
                  :readonly="!editar"
                  :filled="!editar"
                  v-model.trim="convocatoria.vacantes"
                  :rules="[rules.required]"
                  label="Vacantes"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2">
              <v-col cols="12" md="3" class="mt-n3">
                <v-switch
                  class="ml-2"
                  :readonly="!editar"
                  :inset="!editar"
                  v-model="convocatoria.pasantia.value"
                  label="Pasantía"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="9">
                <v-card>
                  <TablaLinks :view="!editar" :disabled="convocatoria.pasantia.value" />
                </v-card>
              </v-col>
            </v-row>

            <Etapas v-if="puesto" :view="!editar || !convocatoria.activa" :convocatoria.sync="convocatoria" :estado="estado" />

            <v-card
              v-if="estado === 'Finalizada'"
              class="px-4 py-2 mb-4"
            >
              <h2 class="font-weight-light">Contratados: {{ contratados.length }}</h2>
              <v-data-table
                :headers="headers"
                :items="contratados"
                :loading="load_cont"
              >
              </v-data-table>
            </v-card>

          </v-form>
        </v-row>

        <v-row v-if="alert" class="d-flex justify-center">
          <v-alert
            border="left"
            text
            dense
            :loading="load"
            :type="message_type"
          >{{ message }}</v-alert>
        </v-row>

        <v-row class="mt-2 mx-2 col-xl-11 d-flex justify-end">
          <v-btn
            class="mb-2 mb-md-0"
            :color="boton_color"
            :disabled="load"
            @click="volver"
          >
            {{ boton_texto }}
          </v-btn>
          <template v-if="!(err404 || load_form)">
            <v-btn
              color="info"
              class="ml-4 mb-2 mb-md-0"
              @click.stop="vista_previa = true"
            >
              Previsualización
              <v-icon right>fas fa-eye</v-icon>
            </v-btn>
            <Convocatoria v-model="vista_previa" :convocatoria="convocatoria" :nueva="nueva" />
          </template>
          <div v-if="!(nueva || load_form)" class="ml-4">
            <Finalizar :convocatoria="id" :disabled="estado === 'Finalizada'" />
          </div>
          <div v-if="editar" class="ml-4">
            <Confirmar :disabled="message_type === 'success'" :loading="load" @action="save" />
          </div>
        </v-row>
      </v-tab-item>

      <template v-if="!nueva">
        <v-tab-item>
          <Postulantes :convocatoria="id" :pasantia="convocatoria.pasantia.value" />
        </v-tab-item>
        <v-tab-item>
          <PostulantesXEtapa :convocatoria="id" />
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { db } from '../../firebase'
import firebase from 'firebase/app'
import router from '../../router'
import Fecha from '../../components/utils/Fecha'
import TablaFunciones from '../../components/tablas/TablaFunciones'
import TablaRequisitos from '../../components/tablas/TablaRequisitos'
import TablaLinks from '../../components/tablas/TablaLinks'
import Convocatoria from '../../components/Convocatoria'
import Confirmar from '../../components/utils/Confirmar'
import Etapas from '../../components/Etapas'
import Postulantes from '../../components/Postulantes'
import PostulantesXEtapa from '../../components/PostulantesXEtapa'
import Finalizar from '../../components/utils/Finalizar'

export default {
  data() {
    return {
      tab: null,
      id: this.$route.params.id,
      editar: false,
      nueva: false,
      load: false,
      load_form: false,
      load_cont: false,
      vista_previa: false,
      alert: false,
      err404: false,
      puesto: false,
      areas_x_empresa: [],
      puestos_x_area: [],
      nombre_provincias: [],
      departamentos: [],
      nombre_conv: '',
      message: '',
      message_type: '',
      title: 'Detalle',
      boton_texto: 'Volver',
      boton_color: 'accent',
      contratados: [],
      headers: [
        { text: 'Apellido', value: 'apellido', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
      ],
      rules: { required: value => !!value || 'Campo requerido' },
      convocatoria: {
        activa: true,
        estado: 'Activa',
        area: { text: '', value: '' },
        area_nombre: '',
        busqueda: '',
        departamento: '',
        empresa: { text: '', value: '' },
        etapas: [],
        fecha: { desde: null, hasta: null },
        localidad: '',
        log: { created: '', updated: '' },
        pasantia: { links: [], pasantes: [], value: false },
        provincia: '',
        puesto: { data: { funciones: [], mision: '', requisitos: [] }, text: '', value: '' },
        vacantes: '',
      }
    }
  },
  async created() {
    this.provincias.forEach(provincia => {
      this.nombre_provincias.push(provincia.nombre)
    })
    if (Number(this.id) === -1) {
      this.set_estado('Activa')
      this.set_funciones([])
      this.set_requisitos([])
      this.set_links([])
      this.editar = true
      this.nueva = true
    } else {
      this.get_badge()
      await this.load_convocatoria()
      if (this.estado === 'Finalizada') {
        this.get_contratados()
      }
      this.puesto = true
    }
  },
  components: {
    Fecha, TablaFunciones, TablaRequisitos, TablaLinks, Convocatoria, Confirmar, Etapas, Postulantes, PostulantesXEtapa, Finalizar
  },
  watch: {
    editar (val) {
      if (val) {
        if (Number(this.id) === -1) {
          this.title = 'Nueva'
          this.boton_texto = 'Cancelar'
          this.boton_color = 'error'
        } else {
          this.title = 'Editar'
          this.boton_texto = 'Cancelar'
          this.boton_color = 'error'
        }
      } else {
        this.title = 'Detalle'
        this.boton_texto = 'Volver'
        this.boton_color = 'accent'
        this.alert = false
        this.message_type = ''
        this.load_convocatoria()
      }
    },
    fecha: {
      handler: function () {
        this.convocatoria.fecha_desde_format = this.formatDate(this.fecha.desde)
        this.convocatoria.fecha_hasta_format = this.formatDate(this.fecha.hasta)
      },
      deep: true
    },
    'convocatoria.activa': {
      handler: function (val) {
        if (this.convocatoria.estado !== 'Finalizada') {
          if (val) {
            this.set_estado('Activa')
          } else {
            this.set_estado('Inactiva')
          }   
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapState(['convocatorias', 'empresas_select', 'fecha', 'estado', 'provincias']),
    ...mapState('funciones', ['funciones']),
    ...mapState('requisitos', ['requisitos']),
    ...mapState('links', ['links']),
  },
  methods: {
    ...mapActions(['set_estado']),
    ...mapActions('funciones', ['set_funciones', 'add_funcion']),
    ...mapActions('requisitos', ['set_requisitos', 'add_requisito']),
    ...mapActions('links', ['set_links', 'add_link']),

    async get_contratados () {
      this.load_cont = true
      this.contratados = []
      await db.collection('convocatorias').doc(this.id).collection('postulantes').where('contratado', '==', true).get()
        .then(async (query) => {
          query.forEach(async (doc) => {
            let postulante = doc.data()
            this.contratados.push({
              nombre: postulante.nombre,
              apellido: postulante.apellido,
            })
          })
        })
        .catch((error) => {
          console.log(error)
        })
      this.load_cont = false
    },

    async save() {
      if (this.$refs.form.validate()) {
        if (this.validaciones()) {
          this.alert = true
          return
        }

        this.load = true

        let convocatoria = this.convocatoria
        convocatoria.estado = this.estado
        convocatoria.fecha = this.fecha
        convocatoria.puesto.data.funciones = this.funciones
        convocatoria.puesto.data.requisitos = this.requisitos
        if (convocatoria.pasantia.value) {
          convocatoria.pasantia.links = this.links
        }
        delete convocatoria.fecha_desde_format
        delete convocatoria.fecha_hasta_format
        convocatoria.log.updated = firebase.firestore.FieldValue.serverTimestamp()

        if (this.nueva) {

          convocatoria.empresa.text = this.empresas_select.find(emp => emp.value === convocatoria.empresa.value).text
          convocatoria.area.text = this.areas_x_empresa.find(emp => emp.value === convocatoria.area.value).text
          convocatoria.log.created = firebase.firestore.FieldValue.serverTimestamp()

          await db.collection('contador_convocatorias').doc('contador').get()
            .then(async (doc) => {
              if (doc.exists) {
                 let contador = doc.data().cantidad
                 contador ++

                 await db.collection('contador_convocatorias').doc('contador').update({ cantidad: contador })
                  .then(async () => {

                    // crea la nueva convocatoria
                    await db.collection('convocatorias').doc(contador.toString()).set(convocatoria)
                      .then(() => {
                        this.message = 'La búsqueda fue guardada correctamente'
                        this.message_type = 'success'
                        this.boton_texto = 'Volver'
                        this.boton_color = 'accent'
                      })
                      .catch((error) => {
                        this.message = error.message
                        this.message_type = 'error'
                      })

                  })
                  .catch((error) => {
                    this.message = error.message
                    this.message_type = 'error'
                  })

              } else {
                this.message = 'No se puede guardar la búsqueda en este momento'
                this.message_type = 'error'
              }
            })
            .catch((error) => {
              this.message = error.message
              this.message_type = 'error'
            })

        } else {

          await db.collection('convocatorias').doc(this.id).update(convocatoria)
            .then(() => {
              this.message = 'La búsqueda fue editada correctamente'
              this.message_type = 'success'
              this.boton_texto = 'Volver'
              this.boton_color = 'accent'
            })
            .catch((error) => {
              this.message = error.message
              this.message_type = 'error'
            })
        }

        this.load = false
        this.alert = true
      }
    },

    async load_convocatoria() {
      this.load_form = true
      this.set_funciones([])
      this.set_requisitos([])
      this.set_links([])

      await db.collection('convocatorias').doc(this.id).get()
        .then(async (doc) => {
          if (doc.exists) {
            this.convocatoria = doc.data()
            this.set_estado(this.convocatoria.estado)
            this.get_departamentos()
            this.get_areas_x_empresa(this.convocatoria.empresa.value)
            this.get_puestos_x_area(this.convocatoria.area.value)

            this.convocatoria.fecha_desde_format = this.formatDate(this.convocatoria.fecha.desde)
            this.convocatoria.fecha_hasta_format = this.formatDate(this.convocatoria.fecha.hasta)

            this.convocatoria.puesto.data.funciones.forEach((funcion) => {
              this.add_funcion(funcion)
            })
            this.convocatoria.puesto.data.requisitos.forEach((requisito) => {
              this.add_requisito(requisito)
            })
            this.convocatoria.pasantia.links.forEach((link) => {
              this.add_link(link)
            })

            await db.collection('areas').doc(this.convocatoria.area.value).get()
              .then((doc) => {
                if (doc.exists) {
                  this.convocatoria.area_nombre = doc.data().nombre
                } else {
                  this.convocatoria.area_nombre = 'N/A'
                }
              })
              .catch((error) => {
                console.log(error)
              })

          } else {
            this.message = 'No se encontró la búsqueda seleccionada'
            this.message_type = 'warning'
            this.err404 = true
            this.alert = true
          }
        })
        .catch((error) => {
          console.log(error)
        })

      this.nombre_conv = this.convocatoria.busqueda
      this.load_form = false
    },

    async get_areas_x_empresa(empresa) {
      this.areas_x_empresa = []
      this.puestos_x_area = []

      if (empresa !== null && empresa !== '') {
        await db.collection('areas').where('inhabilitado', '==', false).where('empresa', '==', empresa).get()
          .then((query) => {
            query.forEach((doc) => {
              this.areas_x_empresa.push({
                text: doc.data().abreviacion,
                value: doc.id,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    async get_puestos_x_area(area) {
      this.puestos_x_area = []

      if (area !== null && area !== '') {
        await db.collection('puestos').where('inhabilitado', '==', false).where('area.value', '==', area).get()
          .then((query) => {
            query.forEach((doc) => {
              this.puestos_x_area.push({
                text: doc.data().nombre,
                value: doc.id,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })

        await db.collection('areas').doc(area).get()
          .then((doc) => {
            this.convocatoria.area_nombre = doc.data().nombre
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    async get_puesto(id) {
      this.puesto = false
      await db.collection('puestos').doc(id).get()
        .then((doc) => {
          if (doc.exists) {
            let puesto = doc.data()

            this.convocatoria.puesto.data.mision = puesto.mision
            this.convocatoria.puesto.text = puesto.nombre
            this.convocatoria.puesto.value = doc.id

            this.set_funciones([])
            this.set_requisitos([])

            puesto.funciones.forEach((funcion) => {
              this.add_funcion(funcion)
            })
            puesto.requisitos.forEach((requisito) => {
              this.add_requisito(requisito)
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.puesto = true
    },

    validaciones() {
      this.message_type = 'error'

      if (this.funciones.length === 0) {
        this.message = 'Debe agregar por lo menos una función principal'
        if (this.requisitos.length === 0) {
          this.message = 'Debe agregar por lo menos un requisito y una función principal'
        }
        return true
      }
      if (this.requisitos.length === 0) {
        this.message = 'Debe agregar por lo menos un requisito'
        return true
      }
      if (this.fecha.desde === null) {
        this.message = 'Seleccione una fecha de inicio'
        if (this.fecha.hasta === null) {
          this.message = 'Seleccione una fecha de inicio y finalización'
        }
        return true
      }
      if (this.fecha.hasta === null) {
        this.message = 'Seleccione una fecha de finalización'
        return true
      }
      if (this.fecha.desde > this.fecha.hasta) {
        this.message = 'La fecha de inicio no puede ser mayor que la de finalización'
        return true
      }

    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    volver() {
      router.push('/admin/convocatorias')
    },

    get_departamentos () {
      this.departamentos = []
      if (this.convocatoria.provincia) {
        this.departamentos = this.provincias.find(prov => prov.nombre === this.convocatoria.provincia).departamentos
      }
    },

    async get_badge () {
      this.$store.state.badge = {
        total: 0,
        visto: 0
      }
      await db.collection('convocatorias').doc(this.id).collection('postulantes').get()
        .then(async (query) => {
          const total = query.size
          await db.collection('convocatorias').doc(this.id).collection('postulantes').where('visto', '==', true).get()
            .then((query) => {
              this.$store.state.badge.total = total
              this.$store.state.badge.visto = query.size
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
}
</script>