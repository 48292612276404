<template>
    <v-dialog
      v-model="dialog"
      max-width="460px"
      :persistent="load"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          title="Deshacer no avanza"
          color="success"
          @click="dialog = true"
        >
          <v-icon small>fas fa-check-circle</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Deshacer no avanza</v-card-title>
        <v-card-text class="body-1">
          ¿Desea cambiar el estado del postulante {{ item.apellido }} {{ item.nombre }} a Continua?
          <v-col cols="12" class="px-0 pb-0">
            <v-textarea
              v-model.trim="comentario"
              label="Comentario"
              rows="3"
              hide-details
              auto-grow
            ></v-textarea>
          </v-col>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn color="success" class="mr-2" :loading="load" @click="desrechazar">Sí</v-btn>
          <v-btn color="error" :disabled="load" @click="dialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import { format_date } from '../../utils'

export default {
  data() {
    return {
      dialog: false,
      load: false,
      comentario: ''
    }
  },
  props: {
    item: Object,
    conv: String,
  },
  computed: {
    ...mapState(['user']),
    postulante: {
      get () {
        return this.item
      },
      set (value) {
        this.$emit('update:item', value)
      }
    },
  },
  watch: {
    dialog (val) {
      if (val) {
        const comentario = this.item.comentario
        if (comentario) {
          this.comentario = comentario.comentario
        }
      }
    }
  },
  methods: {
    async desrechazar () {
      this.load = true

      // actualiza el comentario
      if (this.comentario) {
        try {
          let comentario = this.item.comentario
          // si el comentario no existe lo crea
          if (!comentario) {
            comentario = {
              postulante_id: this.postulante.id,
              etapa: this.postulante.etapa,
              convocatoria: this.conv,
              usuario: this.user.nombre + ' ' + this.user.apellido
            }
            await db.collection('convocatorias').doc(this.conv).get()
              .then((doc) => {
                if (doc.exists) {
                  const convocatoria = doc.data()
                  comentario.busqueda = convocatoria.busqueda
                  comentario.periodo = format_date(convocatoria.fecha.desde) + ' - ' + format_date(convocatoria.fecha.hasta)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          }
          // cambia el comentario por el nuevo
          comentario.comentario = this.comentario
          // guarda el comentario en la coleccion del usuario
          await db.collection('usuarios').doc(comentario.postulante_id).collection('comentarios').doc(comentario.convocatoria).set(comentario)
            .then(() => {
              // actualiza el comentario del postulante en la lista
              this.postulante.comentario = comentario
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error al actualizar el comentario del postulante: ' + error.message,
                color: 'error',
              })
            })
        } catch (error) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error general al actualizar el comentario del postulante: ' + error.message,
            color: 'error',
          })
        }
      }

      await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(this.postulante.id).update({ estado: true })
      .then(async () => {

        // actualiza el estado del usuario
        await db.collection('usuarios').doc(this.postulante.id).get()
          .then(async (doc) => {
            if (doc.exists) {
              let postulaciones = doc.data().postulaciones
              postulaciones.find(post => post.convocatoria === this.conv && post.estado !== 'Cancelada por el postulante').estado = 'En proceso de selección'

              await db.collection('usuarios').doc(this.postulante.id).update({ postulaciones: postulaciones })
                .then(() => {
                  // usuario actualizado
                  this.postulante.activo = true
                  this.dialog = false
                })
                .catch((error) => {
                  console.log(error)
                })

            }
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
      this.load = false
    },
  },
}
</script>