var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 my-3 mx-md-2 mx-0",attrs:{"headers":_vm.headers,"items":_vm.postulantes,"loading":_vm.load,"group-by":"etapa","item-class":_vm.itemRowBackground,"items-per-page":-1},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{staticClass:"body-1",attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"icon":"","data-open":isOpen},on:{"click":toggle}},[_c('v-icon',[_vm._v(_vm._s(isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'))])],1),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center ml-4"},[_c('h1',{staticClass:"text-h5 font-weight-light"},[_vm._v("Estado de la búsqueda: "),_c('strong',{domProps:{"textContent":_vm._s(_vm.estado === 'Activa' ? 'En proceso de selección' : _vm.estado)}})]),_c('v-spacer'),_c('TooltipIcon',{attrs:{"clase":"ma-2","icon":"fas fa-angle-double-up","text":"Contraer todos"},on:{"action":_vm.collapse_all}}),_c('TooltipIcon',{attrs:{"icon":"fas fa-angle-double-down","text":"Expandir todos"},on:{"action":_vm.expand_all}}),_c('TooltipIcon',{attrs:{"load":_vm.load,"clase":"ma-2","icon":"fas fa-sync","text":"Refrescar"},on:{"action":_vm.get_postulantes}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('SendWpp',{attrs:{"numero":item.telefono,"estado":item.estado,"etapa":item.etapa}}),_c('v-icon',{attrs:{"small":"","color":"info","title":"Ver CV"},on:{"click":function($event){return _vm.ver_postulante(item)}}},[_vm._v(" fas fa-eye ")])]}},{key:"no-data",fn:function(){return [(_vm.postulantes.length === 0)?_c('v-alert',{staticClass:"mx-auto my-3",attrs:{"max-width":"400","border":"left","dense":"","text":"","type":"info"}},[_vm._v(" Esta búsqueda no tiene postulantes inscriptos ")]):_c('v-alert',{staticClass:"mx-auto my-3",attrs:{"max-width":"400","border":"left","dense":"","text":"","type":"warning"}},[_vm._v(" No hay datos para los filtros seleccionados ")])]},proxy:true}],null,true)})],1)],1),_c('v-row',{staticClass:"my-1 mx-1 col-xl-11 d-flex justify-end"},[_c('v-btn',{staticClass:"mb-2 mb-md-0 mr-n2 mr-md-0",attrs:{"color":"accent","disabled":_vm.load},on:{"click":function($event){return _vm.$router.push('/admin/convocatorias')}}},[_vm._v(" Volver ")])],1),_c('v-row',{staticClass:"pl-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-badge',{staticClass:"mr-6",attrs:{"color":"yellow lighten-2"}}),_vm._v(" Inscripto en más de una búsqueda ")],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-badge',{staticClass:"mr-6",attrs:{"color":"red lighten-3"}}),_vm._v(" No avanza ")],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-badge',{staticClass:"mr-6",attrs:{"color":"light-green lighten-2"}}),_vm._v(" Contratado ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }