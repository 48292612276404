<template>
  <v-dialog
    v-model="dialog"
    width="450"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ nuevo ? 'Nuevo comentario' : 'Comentario' }}
        <v-spacer></v-spacer>
        <v-switch
          v-if="!nuevo"
          v-model="editar"
          label="Editar"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-textarea
            v-model="comentario.comentario"
            rows="3"
            :readonly="!editar && !nuevo"
            :filled="!editar && !nuevo"
            hide-details
            auto-grow
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          v-if="nuevo"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <Confirmar
          v-else
          nombre="Eliminar"
          icon="fas fa-trash"
          color="error"
          texto="¿Desea eliminar este comentario?"
          @action="eliminar()"
        />
        <Confirmar
          v-if="editar || nuevo"
          clase="ml-4"
          @action="guardar()"
        />
        <v-btn
          v-if="!editar && !nuevo"
          color="accent"
          class="ml-4"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import { format_date } from '../../utils'
import Confirmar from './Confirmar'

export default {
  data () {
    return {
      nuevo: false,
      editar: false,
    }
  },
  props: {
    value: Boolean,
    comentario: Object
  },
  computed: {
    ...mapState(['user']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (!this.comentario.comentario) {
          this.comentario.comentario = ''
          this.nuevo = true
        }
      } else {
        await new Promise(resolve => setTimeout(resolve, 200))
        this.nuevo = false
        this.editar = false
      }
    }
  },
  components: {
    Confirmar
  },
  methods: {
    async eliminar () {
      this.$store.state.loading_screen = true
      try {
        // elimina el comentario en la coleccion del usuario
        await db.collection('usuarios').doc(this.comentario.postulante_id).collection('comentarios').doc(this.comentario.convocatoria).delete()
          .then(() => {
            this.dialog = false
            // emite la accion para que modifique el padre
            this.$emit('eliminar', this.comentario)

            this.$store.dispatch('show_snackbar', {
              text: 'Comentario eliminado correctamente',
              color: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un error al eliminar el comentario: ' + error.message,
              color: 'error',
            })
          })
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error general al eliminar el comentario: ' + error.message,
          color: 'error',
        })
      }
      this.$store.state.loading_screen = false
    },
    async guardar () {
      if (this.comentario.comentario == '') {
        this.$store.dispatch('show_snackbar', {
          text: 'No puede guardar un comentario vacio',
          color: 'warning',
        })
        return
      }
      this.$store.state.loading_screen = true
      try {
        // si es un comentario nuevo trae el nombre y periodo de la convocatoria
        if (this.nuevo) {
          await db.collection('convocatorias').doc(this.comentario.convocatoria).get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data()
                this.comentario.busqueda = data.busqueda
                this.comentario.periodo = format_date(data.fecha.desde) + ' - ' + format_date(data.fecha.hasta)
              }
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error al obtener los datos de la convocatoria ' + this.comentario.convocatoria + ': ' + error.message,
                color: 'error',
              })
              return
            })
        }

        // graba el usuario que mnodifica
        this.comentario.usuario = this.user.nombre + ' ' + this.user.apellido

        // guarda el comentario en la coleccion del usuario
        await db.collection('usuarios').doc(this.comentario.postulante_id).collection('comentarios').doc(this.comentario.convocatoria).set(this.comentario)
          .then(() => {
            this.nuevo = false
            this.editar = false
            // emite la accion para que modifique el padre
            this.$emit('actualizar', this.comentario)

            this.$store.dispatch('show_snackbar', {
              text: 'Comentario guardado correctamente',
              color: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un error al guardar el comentario: ' + error.message,
              color: 'error',
            })
          })

      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error general al guardar el comentario: ' + error.message,
          color: 'error',
        })
      }
      this.$store.state.loading_screen = false
    }
  }
}
</script>